import React,{ useEffect,useState } from 'react';
import {InputText,Form,InputDateField,SelectField} from '../../components/form'
import * as Yup from 'yup';
import { HStack,Box,Heading,CloseButton,Link,Text ,Button,useDisclosure ,Divider} from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import { v4 as uuid } from 'uuid';

 import { BsArrowLeftSquare, BsTypeItalic, BsTypeBold } from "react-icons/bs";

import { toast } from "react-toastify";

function Edit({getPage,rs}){
	const patternData = (rs.pattern != '') ? JSON.parse(rs.pattern) : [];

	const responseData = (rs.response != '') ? JSON.parse(rs.response) : [];
	// console.log(responseData)

	const [patterns, setPatterns] = useState(patternData);
  	const [replies, setReplies] = useState(responseData);

	const [formData, setFormData] = useState({
        name: rs.name,     
        api:rs.api,
        id:rs.id

      });

    const FormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        
    });


    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
    	const formattedPattern = patterns.filter(pattern => pattern.value || pattern.pattern).map(pattern => ({ pattern: pattern.value ? pattern.value : pattern.pattern }));
    	const formattedResponse = replies.filter(response => response.value || response.response).map(response => ({ response: response.value ? response.value : response.response }));

	      // const formattedPattern =  patterns.map(pattern => ({ pattern: pattern.value ? pattern.value : pattern.pattern }));
	      // const formattedResponse = replies.map(response => ({ response:  response.value ? response.value : response.response }));
	   	const data = {
	      ...values,
	      pattern: formattedPattern,
	      response:formattedResponse
	    };
		   
		        
	     const url = `${process.env.REACT_APP_API_URL}/intents/update`;
  		 await fetchWrapper.post(url,data)
  	
  		getPage('list')
  		 toast(`Record has been update `, {
	        type: "info"
	      });
          
         
    }

 
    const api_list = [{name:'Cat 1',id:1},{name:"Cat 2",id:2}]

	const addField = (type) => {
		
		if(type === "input"){
			setPatterns([...patterns, { id: uuid(), value: '' }]);
		}
	   	if(type === "response"){
			setReplies([...replies, { id: uuid(), value: '' }]);
		}
  	};

	const removeFeature = (index) => {
	    setPatterns(patterns.filter((_, i) => i !== index));
	};
	 const removeReply = (index) => {
	 	setReplies(replies.filter((_, i) => i !== index));
	    // setReplies(replies.filter(reply => reply[index] !== id));
  	};

    


	return (

			<>
			
			<Form
                        enableReinitialize
                        validationSchema={FormSchema}
                        initialValues={formData}
                        onSubmit={onSubmit}
             >


      	<HStack mb={5} justifyContent="space-between">       
	        <HStack >
	  				<Box>
	            <a href="/intents"><BsArrowLeftSquare size="24px" /></a>
	          </Box>
	  				<Box ml="15px">


	  				<Heading mb="0px" as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
	  			    Edit Intents
	  			  </Heading>

	  				</Box>
	        </HStack>

	        <HStack>
					<Box w='10%' >
					

					</Box>	
			</HStack>
      	</HStack>

      <HStack spacing='24px' justifyContent="space-between" w="100%" alignItems="unset">
        <HStack w='75%' flexDirection="column" alignItems="unset" gap="0">


          <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
          	<Text mb="10px" fontSize="18px" fontWeight="700">Intent</Text>
            <InputText name="name" title="Name"  />
	          	
          </Box>



          <Box mb="0px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9" gap="0">
            <Text mb="10px" fontSize="18px" fontWeight="700">Patterns</Text>

            <Text mb="0px" fontSize="15px" fontWeight="400">Customer Inputs</Text>

            {patterns.map((pattern, index) => (

		        <HStack key={pattern.id} w="100%" alignItems="baseline">
		          <Box w="80%">
		            <InputText
		              name={`pattern[${index}].value`}
		              title={''}
		              inputProps={{value:pattern.pattern}}
		              onChange={(e) => {
		                const newPattern = [...patterns];
		                newPattern[index].pattern = e;
		                setPatterns(newPattern);
		              }}
		            />
		          </Box>
		          <Box w="20%">
		            <Button onClick={() => removeFeature(index)}>Remove</Button>
		          </Box>
		        </HStack>
		      ))}
	          	
          </Box>

           <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9" textAlign="center" fontSize="13px">
            

            <Link onClick={()=> addField('input')}>Add Another input</Link>
	          	
          </Box>


           <Box mb="0px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
            <Text mb="10px" fontSize="18px" fontWeight="700">Response</Text>

            <Text mb="0px" fontSize="15px" fontWeight="400">Replies</Text>

            {replies.map((response, index) => (
                <HStack key={response.id} w="100%" alignItems="baseline">
                  <Box w="80%">
                    <InputText
                      name={`response[${index}].value`}
                      title={ ''}
                      inputProps={{value:response.response}}
                      onChange={(e) => {
                        const newReplies = [...replies];
                        newReplies[index].response = e;
                        setReplies(newReplies);
                      }}
                    />
                  </Box>
                  <Box w="20%">
                    <Button onClick={() => removeReply(index)}>Remove</Button>
                  </Box>
                </HStack>
              ))}
	          	
          </Box>
     		<Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9" textAlign="center" fontSize="13px">
            	<Link onClick={()=> addField('response')}>Add Another Response</Link>	
          	</Box>

          <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
          	<Text mb="10px" fontSize="18px" fontWeight="700">Action</Text>
            <SelectField name="api" title="Api" list={api_list}  />
	          	
          </Box>

         

         
        </HStack>

        
        
      </HStack>

      <Divider borderColor="#878D92" my="30px" />

      <HStack justifyContent="end">
      		<Box>
      			<Button onClick={()=> getPage('list')}>Cancel</Button>
      		</Box>
      		<Box>
      			<Button
					type='submit'
      				className="primary"
					>Save</Button>


      		</Box>

      </HStack>

      
                        
        </Form>
			</>
		)
}

export default Edit;