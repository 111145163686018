import React from 'react';
import Header from '../components/Header';
import List from './customer/List'
import Navbar from '../components/Navbar'
const Customer = () => {

	return (

			<>
				<Header />
				

				<div className="body_section d_flex">
				<Navbar />

				<div className="dashboard_page">
					<div className="dashboard_section">
						

						<List />
					</div>
				</div>
			</div>
			</>
		)
}

export default Customer;