import React,{useEffect,useState} from 'react';
import CustomModal from '../../components/CustomModal';
import CustomModalLink from '../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import {Link} from 'react-router-dom';
import { toast } from "react-toastify";
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../helpers';
import Pagination from "react-js-pagination";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";

function List(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading,setLoading] = useState(false)
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [listCount, setListCount] = useState(0); 
	const [activePage, setActivePage] = useState(1);
	const [show,setShow] = useState(false);

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
    }, [activePage]);

  const loadList = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/tenent/list?page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.data);
      setListCount(data.count);
      setShow(show => !show);
      setLoading(false)
  }

  const meClose = () => {
  		
		   onClose();
		   
	}
	
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	const meDelete = async (rs) => {
			const aa = window.confirm("Are you sure delete this recored");
			if(aa){
					setLoading(true)
					const url = `${process.env.REACT_APP_API_URL}/api/tenent/${rs.id}`;
	  		 await fetchWrapper.delete(url)
	  		 loadList()
	  		 setLoading(false)
	  		 toast(`Record has been deleted `, {
	        type: "info"
	      });

			}
	}

	const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

  return(
    <>
    	<CustomModalLink
        showModalButtonText="Edit"
        modalHeader="Edit"
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={onCloseEdit}
     		onOpenEdit={onOpenEdit}       
        modalBody={modalEditView}
    	/>

      <div>
      	{loading &&
	      	<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
						  thickness='4px'
						  speed='0.65s'
						  emptyColor='gray.200'
						  color='blue.500'
						  size='xl'
						/>
					</HStack>
				}
				<HStack justifyContent="space-between" mb="25px">
					<Box>
						<Text fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000" my="0px">Customer</Text>
					</Box>

					
				</HStack>

				<div className="table_section">
					<TableContainer width="100%" p="20px">
					  <Table variant='simple'>
					    <Thead>
					      <Tr>
					        <Th>Name</Th>
					       
					      
					        <Th>Contact #</Th>
					        <Th>Email Address</Th>
					        <Th>Domain</Th>
					        <Th></Th>
					      </Tr>
					    </Thead>
					    <Tbody>
					    {list?.map((rs,index) => {
					    		return(
					    			<Tr key={index}>
							        <Td>{rs.name}</Td>
							        
							        
							        <Td>{rs.whatsapp_number}</Td>
							        <Td>{rs.email}</Td>
							        <Td>{rs.domain}</Td>
							        <Td>

								        	<HStack mb="5px">


	 <a  
	 
	 onClick={() => meEdit(rs)}>
        <BsPencil />
      </a>  
      
      </HStack>
								        </Td>
							      </Tr>
					    		)
					    })}
					      
					      
					    </Tbody>
					    
					  </Table>
					  
					</TableContainer>
					<Pagination
							 activePage={activePage}
							 itemsCountPerPage={10}
							 totalItemsCount={listCount}
							 pageRangeDisplayed={5}
							 onChange={onChangePaginate}
							 innerClass="pagination"
							 itemClass="page-item"
							 activeLinkClass="page-link active"
							 linkClass="page-link"
							 prevPageText="Previous"
							 nextPageText="Next"
							/>
				</div>
			</div>

      
		</>
    )
}

export default List;