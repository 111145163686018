import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {SelectField,InputText,Form} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function Edit({rs,loadList,meCloseEdit}){

	const [formData, setFormData] = useState({
    name: rs.name,
   
    
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	  
	  

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/timezone/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
  		 meCloseEdit();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	
	const defaultAccessList = {rows:[{id:1,name:'Active'},{id:0,name:'In Active'}]}



	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Name"  />
			 	

		         <Button py="10px" px="25px" color="#fff" bg="#504F56" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

		          <Button py="10px" px="25px" color="#504F56" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
          		</Button>


			</Form>

			</>

	)
}

export default Edit;