import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
} from '@chakra-ui/react'
import "../../index.css";
import List from './templete/List';
import Create from './templete/Create';
import Edit from './templete/Edit';

function Templete(){
  const [viewPage , SetViewPage]=useState('')


  useEffect(()=>{
    getPage('list')
  },[])


  const getPage = (pageNumber,rs)=>{
    if(pageNumber === "list"){
      SetViewPage(<List getPage={getPage}/>)
    }
    if(pageNumber === "create"){
      SetViewPage(<Create getPage={getPage}/>)
    }

    if(pageNumber === "edit"){
      SetViewPage(<Edit getPage={getPage} rs={rs}/>)
    }
  }

	return(
		<>
			<Box className="setting_detail_section">
         {viewPage}
      </Box>
		</>
	)
}

export default Templete;