import React,{useEffect,useState} from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import "../index.css";
import { Link } from "react-router-dom";
import List from './intents/List'
import Navbar from '../components/Navbar'
import Create from './intents/Create'
import Edit from './intents/Edit'

function Intents(){
	const [viewPage, setViewPage] = useState('');


	useEffect(()=>{
			getPage('list');
	},[])

	const getPage = (pageName,rs)=>{
		if(pageName === 'list'){
			setViewPage(<List getPage={getPage} />)
		}
		if(pageName === 'create'){
			setViewPage(<Create getPage={getPage} />)
		}
		if(pageName === 'edit'){
			setViewPage(<Edit getPage={getPage} rs={rs}/>)
		}
	}

	return(
		<>
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="dashboard_page">
					<div className="dashboard_section">
						

					{viewPage}

					</div>
				</div>
			</div>

			
			
		</>
	)
}

export default Intents;