import React,{useEffect,useState} from 'react';
import { Button,useDisclosure ,HStack,Box} from '@chakra-ui/react'

import * as Yup from 'yup';
import {SelectField,InputText,Form} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; 





function Edit({rs,loadList,meCloseEdit,getPage}){
	const [Type, setType] = useState([]);
	const [editor, setEditor] = useState(rs.content);
	const [formData, setFormData] = useState({
    name: rs.name,
    policy_template_type_id: rs.policy_template_type_id,
    
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   policy_template_type_id: Yup.string().required('Required'),
	  

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   data.content = editor
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/policy_template/update`;
  		 await fetchWrapper.post(url,data)
  		 // loadList()
  		 // meCloseEdit();
  		 getPage('list')
  		 toast(`Record has been update `, {
	        type: "info"
	      });
	}

	useEffect(()=>{
		loadTypeList()
	},[])

	const loadTypeList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/policy_template_type/list`;
      const data  = await fetchWrapper.get(url)
      setType(data.rows);
	}

	
	const defaultAccessList = {rows:[{id:1,name:'Active'},{id:0,name:'In Active'}]}



	return (

			<>
			<HStack justifyContent="space-between" alignItems="center" mb="15px" borderBottom="1px solid #E0E2E4" >
					<Box>
						<h2 fontSize="20px" fontWeight="500" color="#000" my="0px" className="main_heading" >Edit Templete</h2>
					</Box>

					<Box>
						
					</Box>		
				</HStack>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	<Box w="30%" className="InputType">
			 		<InputText name="name" title="Name"  />

			 		<SelectField list={Type} title="Templete Type" name="policy_template_type_id"/>
			 	</Box>

			 	
			 	<div className="detail_card">
					

					<div className="plan_detail_section">
					
						 
						 <ReactQuill theme="snow" value={editor} onChange={setEditor} />
						
					</div>
				</div>

			 	
			 	

			 	
				<HStack justifyContent="end">
					
					<Box>
						<Button py="10px" px="25px" color="#878D92" bg="#FFFFFF" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" border="1px solid #878D92"
				            mb={4}
				            
				            onClick={()=> getPage('list')}
		          			>
		           		 Cancel
		          		</Button>


					</Box>
					<Box>
						<Button py="10px" px="25px" color="#fff" bg="#047E60" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          			>
		           		 Save
		          		</Button>


					</Box>
				</HStack>
		         

			</Form>

			</>

	)
}

export default Edit;