import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../components/form'
import { HStack, Box, Text } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';

import { v4 as uuid } from 'uuid';
function Create({loadList,meClose}){

	
   const [features, setFeatures] = useState([{ id: uuid(), value: '' }]);
	const [formData, setFormData] = useState({
    name: '',
    price:'',
    description:'',

    // monthly_cost: '',
    // yearly_discount: '',
    // subscription_days:'',
    // grace_period:'',
    // allow_story:'',
    // status_id:'',
    // sandbox_url:'',
    // prod_url:'',
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	    price: Yup.number().typeError('Only numbers are allowed').required('Required'),
	   // monthly_cost: Yup.string().required('Required'),
	   
	   // yearly_discount: Yup.string().required('Required'),
	   // subscription_days: Yup.string().required('Required'),
	   // grace_period: Yup.string().required('Required'),
	   // allow_story: Yup.string().required('Required'),
	   // status_id: Yup.string().required('Required'),
	   // sandbox_url: Yup.string().required('Required'),
	   // prod_url: Yup.string().required('Required'),


	});

	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
		 	// const formattedFeatures =  features.map(feature => ({ feature: feature.value }));
		const formattedFeatures = features.filter(feature => feature.value.trim() !== '').map(feature => ({ feature: feature.value }));
		   	const data = {
		      ...values,
		      feature: formattedFeatures,
		    };
		   console.log(data)
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/plan/create`;
  		 await fetchWrapper.post(url,data)
  		 meClose();
  		 loadList();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
  		 
	}

	 const addFeature = () => {
    setFeatures([...features, { id: uuid(), value: '' }]);
  };

  const removeFeature = (id) => {
    setFeatures(features.filter(feature => feature.id !== id));
  };

	


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

				

					<Box w="100%">
			 			<InputText name="name" title="Plan Name"  />
			 		</Box>

			 		<Box w="100%">
			 			<InputText name="price" title="price"  />
			 		</Box>

			 		<Box w="100%">
			 			<InputText name="description" title="description "  />
			 		</Box>


           			<Text mb="0px" fontSize="15px" fontWeight="400">Feature</Text>
		 		    {features.map((feature, index) => (
				        <HStack key={feature.id} w="100%" alignItems="baseline">

				          <Box w="80%">
				            <InputText
				              name={`feature[${index}].value`}
				              title={''}
				              onChange={(e) => {
				                const newFeatures = [...features];
				                newFeatures[index].value = e;
				                setFeatures(newFeatures);
				              }}
				            />
				          </Box>
				          <Box w="20%">
				            <Button onClick={() => removeFeature(feature.id)}>Remove</Button>
				          </Box>
				        </HStack>
				      ))}

			 		<Button mt="" onClick={addFeature}>Add</Button>

			 		
			 		<HStack w="100%" justifyContent="end">
			 			<Box>
			 				
				 		</Box>
				 		<Box>
			 				<Button py="10px" px="25px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>
				 		</Box>
			 		</HStack>

			 			


		         

			</Form>

			</>

	)
}

export default Create;

{/*<HStack>
			 			<HStack width="81%">
					 		<Box w="100%">
					 			<InputText name="monthly_cost" title="Monthly Cost"  />
					 		</Box>

					 		<Box w="100%">
					 			<InputText name="yearly_discount" title="Yearly Discount"  />
					 		</Box>
					 	</HStack>

					 	<Box w="19%">
					 		<Text color="#000" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" textAlign="center">$ Total Yearly Cost</Text>
					 	</Box>
			 		</HStack>

			 		<HStack width="80%">
				 		<Box w="100%">
				 			<InputText name="subscription_days" title="Subscription No of Days"  />
				 		</Box>


				 		<Box w="100%">
				 			<InputText name="grace_period" title="Grace Period"  />
				 		</Box>
			 		</HStack>

			 		<HStack width="80%">
				 		<Box w="100%">
				 			<InputText name="allow_story" title="No of Stories Allowed / Month"  />
				 		</Box>

				 		<Box w="100%">
				 			<SelectField list={{rows:[{name:"In Active",id:'0'},
                    {name:"Active",id:'1'}]}} name="status_id" title="Status"  />
				 		</Box>
				 	</HStack>


				 	<Box w="100%">
					 			<InputText name="sandbox_url" title="Product Price Id (Sandbox)"  />
					 		</Box>


					<Box w="100%">
					 			<InputText name="prod_url" title="Product Price Id (Production)"  />
					 		</Box>*/}