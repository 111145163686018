import React from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import logo from '../images/logo.png'
import { Link,useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { FaCaretDown } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsFillBellFill } from "react-icons/bs";
import "../index.css";
import { useSelector } from 'react-redux';
import { userActions } from '../store';

function Header(){
	const navigate = useNavigate();
 const logout = () => {
      
        localStorage.removeItem('user');
        navigate('/login');
  }
const authUser = localStorage.getItem('user');
	return(
		// <HStack h="70px" bg="#0A83FF" py="10px" px="50px" justifyContent="space-between">
		// 	<Box>
		// 		<HStack>
		// 			<Box>
		// 				<Image src={Headerlogo} alt='Qeekt' />
		// 			</Box>

		// 			<Box ml="60px">
		// 				<HStack>
		// 					<Box me="40px">
		// 						<Link className="header_link" to="/tenent">Tenent</Link>
		// 					</Box>

						


							

		// 					<Box me="40px">
		// 						<Link className="header_link" to="/settings">Settings</Link>
		// 					</Box>

							
		// 				</HStack>
		// 			</Box>
		// 		</HStack>
		// 	</Box>

		// 	<Box>
		// 		<Menu>
		// 		  <MenuButton>
		// 		    <HStack>
		// 		    	<Box>
		// 		    		<Box width="40px" height="40px" borderWidth="1px" borderStyle="solid" borderRadius="50px" display="flex" justifyContent="center" alignItems="center">
		// 		    			<Text fontSize="16px" fontWeight="700" fontFamily="'Montserrat', sans-serif" color="#fff">D</Text>
		// 		    		</Box>
		// 		    	</Box>

		// 		    	<Box>
		// 		    		<Text fontSize="12px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#fff" textAlign="left">Welcome</Text>
		// 		    		<HStack><Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#fff" textAlign="left" display="flex" alignItems="center">Dilawar Abbas</Text> <BsFillCaretDownFill color="#fff" fontSize="10px" /></HStack>
		// 		    	</Box>
		// 		    </HStack>
		// 		  </MenuButton>
		// 		  <MenuList>
		// 		    <MenuItem minH='48px'>
		// 		      <Link onClick={() => logout()}>Logout</Link>
		// 		    </MenuItem>
		// 		  </MenuList>
		// 		</Menu>
		// 	</Box>
		// </HStack>

		<div className="header_section">
				<div className="logo_section">
				<Link to="/">
						<img src={logo} />
				</Link>
				
					{/*<Text mb="0px" fontSize='20px' color="#363A77" fontWeight="700" fontFamily='Montserrat, sans-serif'>QEEKT</Text>*/}
				</div>

				<div className="user_section">
					<ul>
						<li>
							<a href="#">
								<BsFillBellFill />
							</a>
						</li>


						<li>
							<Dropdown>
						      <Dropdown.Toggle id="dropdown-basic">
						        <div className="user_data_section">
									<div className="user_box">
										{/*<h4>{authUser?.full_name[0]}</h4>*/}
									</div>
									
									<div className="text_section">
										<h5>{authUser?.full_name}</h5>
										<p>My Company Name <FaCaretDown size="9px" color="#6C7074" /></p>
									</div>
								</div>
						      </Dropdown.Toggle>

						      <Dropdown.Menu>
						        <Dropdown.Item onClick={()=> logout()}>Logout</Dropdown.Item>
						      </Dropdown.Menu>
						    </Dropdown>
						</li>
					</ul>
				</div>
			</div>
	);
}

export default Header;