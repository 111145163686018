import React from 'react';
import {
  
  InputControl,
  
} from "formik-chakra-ui";

function InputText(props) {

	const {name,type,inputValue,title,onChange} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    };
	return(
		<>
			<div className="field_section">
				
				 <InputControl inputProps={props.inputProps} name={name} label={title} onChange={handleChange}/>
				
			</div>
			
		</>
	);
}

export default InputText;