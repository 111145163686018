import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import LeftNav from './settings/LeftNav';
// import Currency from './settings/Currency';
import TimeZone from './settings/TimeZone';
import Unit from './settings/Unit';
import Weight from './settings/Weight';
import AuthorType from './settings/AuthorType';
import Industry from './settings/Industry';
import policies_icon from '../images/setting_nav_icon/policies_icon.png'
import policies_icon_active from '../images/setting_nav_icon/policies_icon_active.png'
import user_icon from '../images/setting_nav_icon/user_icon.png'
import user_icon_active from '../images/setting_nav_icon/user_icon_active.png'
import User from './settings/User';
import { Routes, Route, Link, Outlet,useLocation } from 'react-router-dom';
import Header from '../components/Header'
import { BsArrowLeftSquare, BsShop } from "react-icons/bs";

function Settings(){
	 const [ getPage, setGetPage ] = useState(false);


	
	 useEffect(() => {
        loadPage('stripe')
       
       
        
    }, []);

	const loadPage = (page_id) => {

		

		if(page_id == 'timezone'){
			setGetPage(<TimeZone />)
		}
		if(page_id == 'unit'){
			setGetPage(<Unit />)
		}
		if(page_id == 'weight'){
			setGetPage(<Weight />)
		}
		if(page_id == 'authortype'){
			setGetPage(<AuthorType />)
		}
		if(page_id == 'industry'){
			setGetPage(<Industry />)
		}

		if(page_id == 'user'){
			setGetPage(<User />)
		}
	} 

	const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = (url) => {
        setActiveItem(url);
    };

	return(
		<>
		{/*<Header />
		
				<div className="setting_page">
					<Box>
						<Text fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000" my="20px">Settings</Text>
						<HStack alignItems="top">
							<Box w="22%">
								<LeftNav loadPage={loadPage} />
							</Box>
		
							<Box w="78%">
								{getPage}
							</Box>
						</HStack>
					</Box>
				</div>*/}

		<Header />

			<div className="body_section">
				<div className="container">
					<div className="setting_page">
						<div className="setting_menu_section">
							<div className="back_menu_section">
								<a href="/">
									<BsArrowLeftSquare />
									<p>Back to Main Menu</p>
								</a>
							</div>

							<div className="menu_section">
								<ul>
									<li className={activeItem === "/settings" ? "active" : ""}>
										<a href="/settings" onClick={() => handleItemClick("/settings")}>
											<div className="img_box">
												<img src={policies_icon} className="icon_img" />
												<img src={policies_icon_active} className="icon_img_active" />
											</div>
											<p>Templates</p>
										</a>
									</li>

									<li className={activeItem === "/settings/user-permission" ? "active" : ""}>
										<a href="/settings/user-permission" onClick={() => handleItemClick("/settings/user-permission")}>
											<div className="img_box">
												<img src={user_icon} className="icon_img" />
												<img src={user_icon_active} className="icon_img_active" />
											</div>
											<p>User and permissions</p>
										</a>
									</li>
								</ul>
							</div>		
						</div>

						<Outlet />
					</div>
				</div>
				
			</div>				
		</>
	);
}

export default Settings;