import logo from './logo.svg';
import './App.css';
import Login from './pages/auth/Login';
import ThankYou from './pages/auth/ThankYou';
import Register from './pages/auth/Register';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Customer from './pages/Customer';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Intents from './pages/Intents';

import Plan from './pages/Plan';
import Invoice from './pages/Invoice';

import Settings from './pages/Settings';
import { ChakraProvider } from '@chakra-ui/react'
import { PrivateRoute } from './components/PrivateRoute';


import UserPermission from './pages/settings/User'
import Templete from './pages/settings/Templete'

function App() {
  return (
    <>
    <ChakraProvider>
        <Routes>            
          <Route path="/" element={
            <PrivateRoute>
                                <Dashboard />
                                
                            </PrivateRoute>

           } />
          

           } />
           <Route path="/dashboard" element={
            <PrivateRoute>
                                <Dashboard />
                                
                            </PrivateRoute>

           } />
          

           } />
          <Route path="/intents" element={

            <PrivateRoute>
                                <Intents />
                                
                            </PrivateRoute>

            } />
          <Route path="/invoice" element={

              <PrivateRoute>
                                <Invoice />
                                
                            </PrivateRoute>
            } />

            <Route path="/customer" element={

              <PrivateRoute>
                                <Customer />
                                
                            </PrivateRoute>
            } />

             <Route path="/plan" element={

              <PrivateRoute>
                                <Plan />
                                
                            </PrivateRoute>
            } />

             <Route path="/settings" element={

              <PrivateRoute>
                                <Settings />
                                
                            </PrivateRoute>
            }>
              

              <Route path="/settings" element={

                <PrivateRoute>
                                  <Templete />
                                  
                              </PrivateRoute>
              } />

              <Route path="/settings/user-permission" element={

                <PrivateRoute>
                                  <UserPermission />
                                  
                              </PrivateRoute>
              } />


            </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/register" element={<Register />} />
         
        </Routes>
      
    </ChakraProvider>
    </>
  );
}

export default App;
