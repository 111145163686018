import React,{useEffect,useState} from 'react';
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
  Spinner,
  Checkbox
} from '@chakra-ui/react'
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../../helpers';
import Pagination from "react-js-pagination";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";

function List({getPage}){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading,setLoading] = useState(false)
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [listCount, setListCount] = useState(0); 
	const [activePage, setActivePage] = useState(1);

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
    }, [activePage]);

  const loadList = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/policy_template/list?page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);
      setListCount(data.count);
      setLoading(false)
  }

  const meClose = () => {
  		
		   onClose();
		   
	}
	
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	

	const meDelete = async (rs) => {
			const aa = window.confirm("Are you sure delete this recored");
			if(aa){
					setLoading(true)
					const url = `${process.env.REACT_APP_API_URL}/api/user/${rs.id}`;
	  		 await fetchWrapper.delete(url)
	  		 loadList()
	  		 setLoading(false)
	  		 toast(`Record has been deleted `, {
	        type: "info"
	      });
			}
	}

	const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

  return(
    <>
    	<CustomModalLink
        showModalButtonText="Edit"
        modalHeader="Edit"
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={onCloseEdit}
     		onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
    	/>
    	
      <div className="setting_page_section">
      	{loading &&
	      	<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
						  thickness='4px'
						  speed='0.65s'
						  emptyColor='gray.200'
						  color='blue.500'
						  size='xl'
						/>
					</HStack>
				}

				<HStack justifyContent="space-between" alignItems="center" mb="15px" borderBottom="1px solid #E0E2E4" >
					<Box>
						<h2 fontSize="20px" fontWeight="500" color="#000" my="0px" className="main_heading" >Templates</h2>
					</Box>

					<Box>
						{/*<CustomModal
              showModalButtonText="Add New Currency"
              modalHeader="Create Currency"
              isOpen={isOpen}
           		onClose={onClose}
           		onOpen={onOpen}
              modalBody=<Create meClose={meClose} loadList={loadList} />
            />*/}

           	<Button mb="20px" className="primary" onClick={()=> getPage('create')}>Add New Templete</Button>
					</Box>		
				</HStack>


				<HStack alignItems="unset" flexDirection='column'>
						<Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
							

				      <div className="table_section">
								<TableContainer>
								  	<Table>
								  		<Thead>
								  			<Tr>
								  			 <Td><Checkbox></Checkbox></Td>
								  				<Th>Template Name</Th>
					        				<Th>Type</Th>
								  				 
								  			</Tr>
								  		</Thead>
									    <Tbody>
									    {list?.map(rs => {
									    	
									    	return (
									      <Tr key={rs.id}>     
									        <Td><Checkbox></Checkbox></Td>
									        <Td style={{ color:'#2F6DC9' ,cursor:'pointer'}} onClick={()=> getPage('edit',rs)}>{rs.name}</Td>
							        		<Td>{rs?.Policy_Template_Type?.name}</Td>
							        
							        
							        
									      </Tr>
									       )
									    }
									    	)}
									    </Tbody>
									  </Table>

								</TableContainer>



							</div>

							
						</Box>
						<Pagination
								 activePage={activePage}
								 itemsCountPerPage={10}
								 totalItemsCount={listCount}
								 pageRangeDisplayed={5}
								 onChange={onChangePaginate}
								 innerClass="pagination"
								 itemClass="page-item"
								 activeLinkClass="page-link active"
								 linkClass="page-link"
								 prevPageText="Previous"
								 nextPageText="Next"
								/>
					</HStack>
			</div>

      
		</>
    )
}

export default List;