import React,{useState} from 'react';
import { BsFillGrid1X2Fill, BsInboxFill, BsFillTagFill, BsPersonFill, BsFillChatLeftFill, BsFillChatSquareQuoteFill } from "react-icons/bs";
import Dropdown from 'react-bootstrap/Dropdown';
import dashboard_icon from '../images/main_nav_icon/dashboard_icon.svg'
import dashboard_icon_active from '../images/main_nav_icon/dashboard_icon_active.svg'
import order_icon from '../images/main_nav_icon/order_icon.svg'
import order_icon_active from '../images/main_nav_icon/order_icon_active.svg'
import product_icon from '../images/main_nav_icon/product_icon.svg'
import product_icon_active from '../images/main_nav_icon/product_icon_active.svg'
import customer_icon from '../images/main_nav_icon/customer_icon.svg'
import customer_icon_active from '../images/main_nav_icon/customer_icon_active.svg'
import chat_icon from '../images/main_nav_icon/chat_icon.svg'
import chat_icon_active from '../images/main_nav_icon/chat_icon_active.svg'
import campaign_icon from '../images/main_nav_icon/campaign_icon.svg'
import campaign_icon_active from '../images/main_nav_icon/campaign_icon_active.svg'
import bot_icon from '../images/main_nav_icon/bot_icon.svg'
import facebook_icon from '../images/main_nav_icon/facebook_icon.png'
import setting_icon from '../images/main_nav_icon/setting_icon.svg'
import intent_icon_active from '../images/main_nav_icon/intent_icon_active.svg'
import facebook_icon_active from '../images/main_nav_icon/facebook_icon_active.svg'
import { Routes, Route, Link,useLocation } from 'react-router-dom';
function Navbar() {
	const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = (url) => {
        setActiveItem(url);
    };

	return(
		<>
			<div className="navbar_section">
				<ul>
					<li>
						<a href="/" onClick={() => handleItemClick("/")} className={activeItem === "/" ? "active" : ""}>
							<div className="img_box">
								<img src={dashboard_icon} className="icon_img" />
								<img src={dashboard_icon_active} className="icon_img_active" />
							</div>
							<p>Dashboard</p>
						</a>
					</li>

					<li>
						<a href="/customer" onClick={() => handleItemClick("/customer")} className={activeItem === "/customer" ? "active" : ""}>
							<div className="img_box">
								<img src={customer_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Customers</p>
						</a>
					</li>

					<li>
						<a href="/plan" onClick={() => handleItemClick("/plan")} className={activeItem === "/plan" ? "active" : ""}>
							<div className="img_box">
								<img src={customer_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Plans</p>
						</a>
					</li>

					<li>
						<a href="/invoice" onClick={() => handleItemClick("/invoice")} className={activeItem === "/invoice" ? "active" : ""}>
							<div className="img_box">
								<img src={customer_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Invoices</p>
						</a>
					</li>

					<li>
						<a href="/intents" onClick={() => handleItemClick("/intents")} className={activeItem === "/intents" ? "active" : ""}>
							<div className="img_box">
								<img src={customer_icon} className="icon_img" />
								<img src={intent_icon_active} className="icon_img_active" />
							</div>
							<p>Intents</p>
						</a>
					</li>

					<li>
						<a href="/settings" onClick={() => handleItemClick("/settings")} className={activeItem === "/settings" ? "active" : ""}>
							<div className="img_box">
								<img src={setting_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Settings</p>
						</a>
					</li>
				</ul>
			</div>
			
			
			
		</>
	);
}

export default Navbar;