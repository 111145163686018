import React from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar'
import { HStack, Box, Image, Link, Text, Heading } from '@chakra-ui/react';
import "../index.css";

function Dashboard(){
	return(
		<>
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="dashboard_page">
					<div className="dashboard_section">
						<h2 className="main_heading">Dashboard</h2>

						<HStack>
							<Box className="box_section">
								<Heading>1,000</Heading>
								<Text>Total Signups</Text>
							</Box>

							<Box className="box_section">
								<Heading>1,000</Heading>
								<Text>Active Accounts</Text>
							</Box>

							<Box className="box_section">
								<Heading>1,000</Heading>
								<Text>Total Signups</Text>
							</Box>
						</HStack>
					</div>
				</div>
			</div>
		</>
	)
}

export default Dashboard;